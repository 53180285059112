<div *ngIf="!hideFilters" class="step-label cwk-orange-bg cwk-white px-3 py-2"><b>Step {{ isOnline ? '1' : '2' }}:</b> Select Age &amp; Pathway</div>
<!--div *ngFor="let location of locations; let isFirst = isFirst" class="mt-2 d-block d-lg-none">
  <img [src]="constants.PIN_YELLOW" alt="Map pin" loading="lazy" width="25px" height="30px">
  &nbsp;{{ location.Name }}, {{ location.City }} {{ location.State }}
  <span *ngIf="isFirst">&nbsp;&nbsp;<span class="cwk-link cwk-blue-link" (click)="scroll2StepOne()">[Change location]</span></span>
</div-->

<!-- FILTERS & CALENDAR HEADER -->
<div #header id="header" class="header sticky-top">

  <div [hidden]="hideFilters">

    <!-- AGE FILTER -->
    <div class="fix-row py-3">
      <div *ngFor="let pathwayAge of pathwayAges" class="col">
        <div id="{{ 'age-switched-' + pathwayAge.ageMin + (pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+') }}" class="age-btn" [ngClass]="{'selected' : pathwayFilterModel?.ageUid === pathwayAge.uid}" (click)="switchAgeFilter(pathwayAge)">
          {{ pathwayAge.ageMin }}{{ pathwayAge.ageMax ? ('-' + pathwayAge.ageMax) : '+' }}
        </div>
      </div>
    </div>

    <!-- PATHWAY FILTER -->
    <div *ngIf="isReadingPathways" class="mt-3 cwk-loading">
      <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Reading pathways, please wait...
    </div>
    <ng-container *ngIf="!isReadingPathways">

      <!-- DESKTOP -->
      <div class="d-none d-lg-block">
        <div class="fix-row mb-1 mb-lg-3 g-0">
          <div id="{{ 'pathway-switched-' + pathway.Name }}" class="col-2 col-md text-center cwk-pointer" *ngFor="let pathway of pathways" [ngClass]="{'not-allowed' : pathway.IsDisabled}" (click)="switchPathwayFilter(pathway)">
            <div class="pathway-icon">
              <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFileShow }}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid" height="94" width="94" />
            </div>
            <div class="pathway-name">{{ pathway.Name }}</div>
          </div>
        </div>
      </div>

      <!-- MOBILE -->
      <div class="d-lg-none">
        <div class="fix-row mb-1 mb-lg-3 g-0">
          <div id="{{ 'pathway-switched-' + pathway.Name }}" class="col-2 col-md text-center cwk-pointer" *ngFor="let pathway of pathways" [ngClass]="{'not-allowed' : pathway.IsDisabledMobile}" (click)="switchPathwayFilter(pathway)">
            <div class="pathway-icon">
              <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFileShowMobile }}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid" height="94" width="94" />
            </div>
            <div class="pathway-name">{{ pathway.Name }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- CHOOSE YOUR CAMP, SEASON, AND ONLY OPEN CLASSES FILTER (DESKTOP) -->
    <div *ngIf="isOnline" class="cwk-orange-bg cwk-white mt-2 px-3 py-2">
      <ng-container *ngTemplateOutlet="chooseCamp; context: { header: '<b>Step 2:</b> Pick your camp' }"></ng-container>
    </div>

    <!-- CLASS/HYBRID+ FILTER FOR LOCATIONS >
    <div *ngIf="!isOnline" class="mt-3 mb-1">
      <div class="mb-0 mb-lg-2 d-flex justify-content-between align-items-baseline">
        <div class="step-label d-none d-lg-block"><b>Step 2:</b> Pick Your Camp</div>
        <div *ngIf="!forceHybridOnly" class="program-type-filter d-flex align-items-center">
          <div class="cwk-pointer me-3 px-1" (click)="isOnlineFilterChanged(null)">
            <fa-icon class="me-2" [icon]="isHybridOnly === null ? faDotCircle : faCircleO" size="lg"></fa-icon>
            <span>All Camps</span>
          </div>
          <div class="hybrid cwk-pointer me-3 px-1" (click)="isOnlineFilterChanged(true)">
            <fa-icon class="me-2" [icon]="isHybridOnly ? faDotCircle : faCircleO" size="lg"></fa-icon>
            <span>Hybrid+</span>
          </div>
          <div class="classic cwk-pointer px-1" (click)="isOnlineFilterChanged(false)">
            <fa-icon class="me-2" [icon]="isHybridOnly === false ? faDotCircle : faCircleO" size="lg"></fa-icon>
            <span>Classic</span>
          </div>
        </div>
      </div>
    </div-->

    <!-- CHOOSE YOUR CAMP, LOCATION NAME, QUICK TIP -->
    <div *ngIf="!isOnline" class="mt-3 mt-lg-5">
      <div class="step-label cwk-orange-bg cwk-white px-3 py-2">
        <ng-container *ngTemplateOutlet="chooseCamp; context: { header: '<b>Step 3:</b> Choose your camp' }"></ng-container>
      </div>
      <div class="d-none d-lg-block">
        <div class="fix-row my-2 flex-wrap location-row">
          <div class="col-md-12 col-lg-5">
            <div *ngFor="let location of locations; let isFirst = first">
              <img [src]="constants.PIN_YELLOW" alt="Map pin" loading="lazy" width="25px" height="30px">
              &nbsp;{{ location.Name }}, {{ location.City }} {{ location.State }}
              <span *ngIf="isFirst">&nbsp;&nbsp;<span class="cwk-link cwk-blue-link" (click)="scroll2StepOne()">[Change location]</span></span>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="d-flex flex-wrap align-items-center">
              <img class="img-fluid" width="25px" alt="Lit light bulb" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/in-person-camps/lit-light.png" loading="lazy" />
              <span class="cwk-darkyellow mx-2"><b>Quick Tip!</b></span>
              Use arrows to flip through weeks and find available dates & times
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TEMPLATE: CHOOSE YOUR CAMP STEP BAR -->
  <ng-template #chooseCamp let-header="header">
    <div class="fix-row flex-wrap location-row">
      <div class="col-12 col-lg-5">
        <div class="step-label" [innerHtml]="header"></div>
      </div>
      <div class="col-lg-7">
        <div class="d-none d-lg-flex justify-content-between h-100">
          <div class="d-flex">

            <!-- SHOW THE SEASON SELECTOR IF THERE IS MORE THAN ONE SEASON -->
            <div class="cwk-pointer d-flex align-items-center" *ngIf="seasons.length > 1">
              <div *ngFor="let season of seasons" (click)="setSeason(season)" class="me-3">
                <fa-icon class="me-2" [icon]="selectedSeason?.Code === season.Code ? faDotCircle : faCircleO" size="lg"></fa-icon>
                <span>{{ season.DisplayName }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div (click)="setOpenClassesOnly(true)" class="cwk-pointer d-flex align-items-center me-3">
              <fa-icon class="me-2" [icon]="isOpenClassesOnly ? faDotCircle : faCircleO" size="lg"></fa-icon>
              <span>Open Camps Only</span>
            </div>
            <div (click)="setOpenClassesOnly(false)" class="cwk-pointer d-flex align-items-center">
              <fa-icon class="me-2" [icon]="isOpenClassesOnly ? faCircleO : faDotCircle" size="lg"></fa-icon>
              <span>All Camps</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- CURRENT SELECTION AND WEEK SELECTOR (LG, XL ONLY) -->
  <div class="fix-row">
    <div class="col-md-12 col-lg-5 d-none d-lg-block">
      <div class="h-100 d-flex flex-column justify-content-center align-items-center registrations-bg-dark-grey">
        <div style="font-size: 21px;"><b>{{ pathwayFilterModel.displayText }}</b></div>
        <div style="margin-top: 5px;" class="cwk-orange">{{ pathwayFilterModel.timeText }}</div>
      </div>
    </div>
    <div class="col-lg-7 d-none d-lg-block">
      <div class="calendar-heading h-100 registrations-bg-dark-grey">
        <div class="d-flex flex-row justify-content-between align-items-center">

          <!-- PREVIOUS WEEK BUTTON -->
          <div id="calendar-reverse" class="cwk-pointer" style="width: 15%;" [ngClass]="{ 'disabled' : !weekReverseActive() }" (click)="moveWeek('REVERSE')">
            <fa-icon [icon]="faChevronLeft" class="arrow left ms-2" size="2x"></fa-icon>
          </div>

          <!-- CALENDAR TITLE -->
          <div *ngIf="weeks.length" ngbDropdown>
            <button #datePicker class="date-picker-btn unit-title p-1 cwk-pointer" ngbDropdownToggle>
              <div class="d-flex">
                <fa-icon [icon]="faCaretDown" size="lg"></fa-icon>
                <div class="mx-2 d-flex align-items-center"><!--span class="season-display-name" *ngIf="selectedSeason">{{ selectedSeason.DisplayName }}</span-->Week {{ weeks[selectedWeek]?.monday | date:'M/dd' }} - {{ weeks[selectedWeek]?.friday | date:'M/dd' }}</div>
                <fa-icon [icon]="faCaretDown" size="lg"></fa-icon>
              </div>
            </button>
            <div class="dropdown-menu dropdown-menu-center p-0" ngbDropdownMenu>
              <ngb-datepicker class="p-0"
                [dayTemplate]="customDayTemplate"
                [firstDayOfWeek]="7"
                [markDisabled]="ngbIsDisabled"
                [navigation]="'arrows'"
                [startDate]="ngbDPData.model"
                [minDate]="ngbDPData.minDate"
                [maxDate]="ngbDPData.maxDate"
                (dateSelect)="ngbDateSelected($event)"
                (click)="$event.stopPropagation()"
                [(ngModel)]="ngbDPData.model">
              </ngb-datepicker>
              <ng-template #customDayTemplate let-date let-disabled="disabled">
                <span class="custom-day" [class.highlight-day]="ngbShouldHighlightDay(date)" [class.text-muted]="disabled">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>

          <!-- NEXT WEEK BUTTON -->
          <div id="calendar-forward" class="d-flex align-items-center justify-content-end cwk-pointer" style="width: 15%;" [ngClass]="{ 'disabled' : !weekForwardActive() }" (click)="moveWeek('FORWARD')">
            <fa-icon [icon]="faChevronRight" class="arrow right me-2" size="2x"></fa-icon>
          </div>
        </div>

        <div class="d-flex justify-content-center cwk-grey-bg" *ngIf="isOnline">
          <div class="time-window-header">CAMP START TIME</div>
        </div>

        <!-- TIMEFRAMES FOR NON-LOCATION SPECIFIC CAMPS -->
        <div *ngIf="isOnline" class="d-flex justify-content-between cwk-grey-bg">
          <div *ngFor="let timeWindow of timeWindows; first as isFirst, last as isLast, index as i" class="time-window-item">
            <span *ngIf="isFirst">Before</span>
            <span *ngIf="!isFirst">{{ timeWindows[i - 1] | date:'shortTime' }}&#32;</span>
            <span *ngIf="!isLast">
              <span [hidden]="isFirst">-</span>&#32;{{ timeWindow | date:'shortTime' }}
            </span>
            <span *ngIf="isLast">&amp; After</span>
          </div>
        </div>

        <!-- TIMEFRAMES FOR LOCATION SPECIFIC CAMPS (THESE NEED TO BE SPECIALLY HANDLED) -->
        <div *ngIf="!isOnline" class="d-flex justify-content-between cwk-grey-bg">
          <div *ngFor="let timeWindow of timeWindows; first as isFirst " class="time-window-item">
            <!--span *ngIf="isFirst; else pm">{{ weeks[selectedWeek]?.minStart | date:'shortTime' }} - {{ weeks[selectedWeek]?.minEnd | date:'shortTime' }}</span>
            <ng-template #pm>{{ weeks[selectedWeek]?.maxStart | date:'shortTime' }} - {{ weeks[selectedWeek]?.maxEnd | date:'shortTime' }}</ng-template-->
            <span>{{ isFirst ? 'MORNING' : 'AFTERNOON' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CAMPS -->
<div *ngIf="isReading" class="mt-3 cwk-loading">
  <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Reading camps, please wait...
</div>
<div *ngIf="!isReading" [@slideInOut]>

  <!-- DESKTOP -->
  <div class="d-none d-lg-block">

    <div *ngFor="let _class of classes2Show; first as isFirst" id="{{ 'classId-' + _class.ClassId }}" [ngClass]="{'border-top-mobile': !isFirst, 'mt-3' : isFirst}" class="fix-row mb-3">

      <!-- LEFT SIDE OF TABLE -->
      <div class="col-lg-5">

        <!-- CLASS INFO (DESKTOP)  -->
        <div id="{{ 'open-class-details-' + _class.LevelCode }}" class="d-flex flex-row class-container" (click)="openClassDetails(_class)">

          <!-- CLASS IMAGE -->
          <div class="d-flex flex-column position-relative">
            <img class="ROW-HEIGHT-IMG" alt="{{ _class.ClassName }} image" src="https://d3t4xfu733v2tb.cloudfront.net/classes/{{ _class.ImagePath }}" loading="lazy" height="100" width="100" />

            <!-- "NEW" IMAGE -->
            <div *ngIf="_class.IsNew" class="new-class-container">
              <img class="new-class-img" alt="New camp!" src="https://d3t4xfu733v2tb.cloudfront.net/classes/new-corner-orange.png" loading="lazy" height="70" width="70" />
            </div>
          </div>

          <!-- CLASS DETAILS -->
          <div class="d-flex flex-column justify-content-between w-100 p-1">
            <div class="ms-2 me-2">
              <div class="fw-bold"><span class="new" *ngIf="_class.IsNew">*NEW* </span>{{ _class.ClassName }}</div>
              <div class="ages">{{ 'Ages ' + _class.AgeMin + (_class.AgeMax ? ('-' + _class.AgeMax) : '+') }}</div>
            </div>

            <!-- LOWER HALF, PATHWAY SKILL -->
            <div class="lower-half ms-1 mb-1">
              <ng-container *ngFor="let pathway of _class.Pathways | slice:0:1">
                <span *ngFor="let skill of pathway.Skills" class="me-2 skill-container BACKGROUND-{{ skill }}">
                  {{ skill }}
                </span>
              </ng-container>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-between align-items-end mx-1 my-2">
            <div style="width: 27px; height: 27px;">
              <img style="border-radius: 50%" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/camp-special/info-icon.png" alt="Info icon" loading="lazy" class="img-fluid cwk-white-bg" height="27" width="27" />
            </div>
            <div class="d-flex">
              <div *ngFor="let pathway of _class.Pathways; last as isLast" style="width: 27px; height: 27px;" [ngClass]="{ 'me-2' : !isLast }" ngbPopover="{{ pathway.Name }}" triggers="mouseenter:mouseleave">
                <img src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFile }}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid" height="27" width="27" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- RIGHT SIDE OF TABLE -->
      <div class="col-lg-7">

        <!-- HALF DAY CAMPS -->
        <div class="d-flex flex-row justify-content-between sessions-container" *ngIf="!_class.IsFullDay">
          <div *ngFor="let sessions of _class.sessionsByWeek[selectedWeek]; let weekDayIndex = index" class="d-flex flex-column w-100 {{ isOnline ? 'mx-1' : 'mx-ampm' }}">
            <ng-container *ngFor="let session of sessions; first as isFirst">
              <div class="d-flex flex-column item-session" [ngClass]="{'mt-2' : !isFirst, 'location': !isOnline && (locations.length > 1)}">
                <ng-container *ngIf="!session.IsEmpty">
                  <ng-container *ngTemplateOutlet="campTile; context: { isOnline: isOnline, locations: locations, session: session }"></ng-container>
                </ng-container>

                <!-- NO CLASSES FOR THIS SELECTED WEEK. SHOW 'NOT CURRENTLY OPEN' OR WHEN THE NEXT FUTURE CLASS IS -->
                <ng-container *ngIf="session.IsEmpty">
                  <ng-container *ngTemplateOutlet="emptyTile; context: { weekDayIndex: weekDayIndex }"></ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>

        </div>

        <!-- FULL DAY CAMPS -->
        <div class="d-flex flex-row justify-content-between sessions-container" *ngIf="_class.IsFullDay">
          <ng-container *ngFor="let session of _class.sessionsByWeek[selectedWeek][0]; let weekDayIndex = index, first as isFirst">
            <div class="item-session w-100 {{ isOnline ? 'mx-1' : 'mx-ampm' }}" [ngClass]="{'mt-2' : !isFirst, 'location': !isOnline && (locations.length > 1)}">
              <ng-container *ngIf="!session.IsEmpty">
                <ng-container *ngTemplateOutlet="campTile; context: { isOnline: isOnline, locations: locations, session: session }"></ng-container>
              </ng-container>

              <!-- NO CLASSES FOR THIS SELECTED WEEK. SHOW 'NOT CURRENTLY OPEN' OR WHEN THE NEXT FUTURE CLASS IS -->
              <ng-container *ngIf="session.IsEmpty">
                <ng-container *ngTemplateOutlet="emptyTile; context: { weekDayIndex: weekDayIndex }"></ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <!-- TEMPLATE: CAMP TILE -->
        <ng-template #campTile let-isOnline="isOnline" let-locations="locations" let-session="session">

          <!-- STACK EFFECT -->
          <div class="stack-container" *ngIf="isOnline">
            <div *ngIf="session.NumberStacked" class="relative-container w-100">
              <img alt="Stacked classes" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/locations/registrations/session-stack-icon{{ session.NumberOccupied ? '-popular' : '' }}.png" class="img-fluid" loading="lazy" height="20" width="140" />
              <div *ngIf="session.NumberOccupied" class="stacked-text">
                <span class="inner">POPULAR</span>
              </div>
            </div>

            <!--div *ngIf="!isOnline" class="program-type">
              {{ session.IsOnline ? 'HYBRID+' : 'CLASSIC' }}
            </div-->
          </div>

          <!-- TILE CONTENT -->
          <div id="{{ 'session-' + session.LessonId }}" tm-destination="/checkout" class="d-flex flex-column align-items-center justify-content-between session-content" (click)="goSignup(session)" [ngClass]="{ 'hybrid': (!isOnline && session.IsOnline), 'classic': (!isOnline && !session.IsOnline), 'location': !isOnline && (locations.length > 1), 'popular' : session.NumberOccupied, 'full' : session.Occupied, 'ended' : session.HasEnded}" [ngbPopover]="(session.SessionInfo || session.Discounts2Show.length) ? popDiscounts : null" container="body" triggers="mouseenter:mouseleave">

            <!-- TIMES -->
            <div class="time item">
              {{ session.StartUTC | date:'h:mm' }} - {{ session.EndUTC | date:'h:mm aa' }}
            </div>

            <!-- WEEKDAYS -->
            <div class="price item">
              <span>
                {{ session.SessionStartUTC | date:'EEEE' }} - {{ session.SessionEndUTC | date:'EEEE' }}
                <fa-icon class="ms-1" [icon]="faCircleInfo" *ngIf="session.SessionInfo && (session.NumberOccupied || session.SpotsLeft <= 3)"></fa-icon>
              </span>
            </div>

            <!-- LOCATION -->
            <div class="location item" *ngIf="!isOnline && locations.length > 1">
              {{ session.Location }}
            </div>

            <!-- OCCUPIED / LAST SPOTS -->
            <div class="item">
              <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>

              <!-- IF THERE IS NO SPOTS LEFT MESSAGE, SHOW SESSION INFO -->
              <div class="session-info" *ngIf="session.SessionInfo && !session.NumberOccupied && session.SpotsLeft > 3">{{ session.SessionInfo }}</div>
            </div>

            <!-- FINISHED/WAITLIST/REGISTER & PRICE -->
            <div class="register">
              {{ session.HasEnded ? 'ENDED' : (session.HasStarted ? 'IN-PROGRESS' : (session.Occupied ? 'Waitlist' : 'Register')) }}

              <!-- SHOW PRICE IF THE SESSION IS OPEN FOR REGISTRATION -->
              <span [hidden]="session.HasEnded || session.Occupied">
                <span *ngIf="!session.Discounts.length else discounted">
                  {{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                </span>
                <ng-template #discounted>
                  {{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                  <span class="non-discounted">&#32;{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</span>
                </ng-template>
              </span>

              <!-- IF THE SESSION IS STILL AVAILABLE FOR REGISTRATION SHOW THE ONLINE/EXTERNAL ICONS -->
              <span [hidden]="session.HasEnded">
                <span *ngIf="!isOnline && session.IsOnline" class="online-icon" ngbPopover="This camp is taught online" container="body" triggers="mouseenter:mouseleave">O</span>
                <fa-icon *ngIf="session.IsExternalRegistration" [icon]="faExternalLinkAlt" class="ms-2" ngbPopover="Camp registration handled externally" container="body" triggers="mouseenter:mouseleave"></fa-icon>
              </span>
            </div>
          </div>

          <!-- TEMPLATE: POPOVER FOR DISCOUNTS -->
          <ng-template #popDiscounts>
            <div *ngFor="let discount of session.Discounts2Show">
              {{ discount.Description }}{{ discount.ExpiresOn ? ' expires on ' + (discount.ExpiresOn | date:'shortDate') + ' at ' + (discount.ExpiresOn | date:'shortTime') + ' (' + timezoneAbbr + ')' : '' }}
            </div>
            <hr class="my-1" *ngIf="session.SessionInfo && session.Discounts2Show.length" />
            <div *ngIf="session.SessionInfo">{{ session.SessionInfo }}</div>
          </ng-template>
        </ng-template>

        <!-- TEMPLATE: EMPTY CAMP TILE -->
        <ng-template #emptyTile let-weekDayIndex="weekDayIndex">

          <!-- No current class and no future class for the selected season -->
          <div *ngIf="!_class.sessionsByWeekFuture[selectedWeek][weekDayIndex].length || (selectedSeason && _class.sessionsByWeekFuture[selectedWeek][weekDayIndex][0].SeasonCode !== selectedSeason.Code) || isOpenClassesOnly; else futureClass">
            <div class="d-flex flex-column align-items-center justify-content-between more-coming-soon disabled" [ngClass]="{ 'mt-0': !isOnline, 'location': !isOnline && (locations.length > 1) }">
              <div class="top-bar">&nbsp;</div>
              <div class="item"></div>
              <div class="item"></div>
              <div class="item"></div>
            </div>
          </div>

          <!-- TEMPLATE: THERE IS A FUTURE CLASS, SHOW IT! -->
          <ng-template #futureClass>

            <!--div *ngIf="!isOnline" class="stack-container" style="margin-bottom: -20px;">
              <div class="program-type">{{ getOfferedProgramsText4FutureSessions(_class.sessionsByWeekFuture[selectedWeek][weekDayIndex]) }}</div>
            </div-->

            <div id="{{ 'next-classes-' + _class.LevelCode }}" class="d-flex flex-column align-items-center justify-content-between more-coming-soon session-content" (click)="openFutureSessionsDialog(_class, _class.sessionsByWeekFuture[selectedWeek][weekDayIndex])" [ngClass]="{ 'mt-0': !isOnline, 'location': !isOnline && (locations.length > 1) }" *ngIf="!isOpenClassesOnly">
              <div class="top-bar strong">UPCOMING</div>
              <div class="item strong">Next Camp</div>
              <div class="item strong">Starts {{ _class.sessionsByWeekFuture[selectedWeek][weekDayIndex][0].SessionStartUTC | date:'M/d' }}</div>
              <div class="item strong" style="color: black;"><b>View upcoming</b></div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- MOBILE -->
  <div class="d-lg-none">

    <div *ngFor="let _class of classes2ShowMobile; first as isFirst" id="{{ 'classId-' + _class.ClassId }}" [ngClass]="{'border-top-mobile': !isFirst, 'mt-3' : isFirst}" class="mb-3">

      <!-- CLASS INFO (MOBILE) -->
      <div class="d-flex flex-row class-container" (click)="classClicked(_class)">

        <!-- CLASS IMAGE -->
        <div class="d-flex flex-column position-relative">
          <img class="ROW-HEIGHT-IMG" alt="{{ _class.ClassName }} image" src="https://d3t4xfu733v2tb.cloudfront.net/classes/{{ _class.ImagePath }}" loading="lazy" height="100" width="100" />

          <!-- "NEW" IMAGE -->
          <div *ngIf="_class.IsNew" class="new-class-container">
            <img class="new-class-img" alt="New camp!" src="https://d3t4xfu733v2tb.cloudfront.net/classes/new-corner-orange.png" loading="lazy" height="70" width="70" />
          </div>
        </div>

        <!-- CLASS DETAILS -->
        <div class="d-flex flex-column justify-content-between w-100 p-1">
          <div class="ms-2 me-2">
            <div class="fw-bold"><span class="new" *ngIf="_class.IsNew">*NEW* </span>{{ _class.ClassName }}</div>
            <div class="ages">{{ 'Ages ' + _class.AgeMin + (_class.AgeMax ? ('-' + _class.AgeMax) : '+') }}</div>
          </div>

          <!-- LOWER HALF, PATHWAY SKILL. IF THE CLASS BELONGS TO MULTIPLE PATHWAYS IT IS ASSUMED THEY BELONG TO THE SAME SKILL -->
          <div class="lower-half ms-1 mb-1">
            <ng-container *ngFor="let pathway of _class.Pathways | slice:0:1">
              <span *ngFor="let skill of pathway.Skills" class="me-2 skill-container BACKGROUND-{{ skill }}">
                {{ skill }}
              </span>
            </ng-container>
          </div>
        </div>

        <div class="d-flex flex-column justify-content-between align-items-end mx-1 my-2">
          <div class="d-flex">
            <div *ngFor="let pathway of _class.Pathways; last as isLast" style="width: 20px; height: 20px;" [ngClass]="{ 'me-2' : !isLast }" ngbPopover="{{ pathway.Name }}" triggers="mouseenter:mouseleave">
              <img src="https://d3t4xfu733v2tb.cloudfront.net/web/coders-pathways/pathways/{{ pathway.IconFile }}" alt="{{ pathway.Name }} icon" loading="lazy" class="img-fluid" height="20" width="20" />
            </div>
          </div>
          <!--div style="width: 20px; height: 20px;">
            <img style="border-radius: 50%" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/camp-special/info-icon.png" alt="Info icon" loading="lazy" class="img-fluid cwk-white-bg"/>
          </div-->
          <fa-icon class="d-lg-none" [icon]="_class.isExpanded ? faMinusCircle : faPlusCircle" size="lg"></fa-icon>
        </div>
      </div>

      <!-- WHEN EXPANDED SHOW CLASS DESCRIPTION, EXPERIENCE AND PREREQUISITES -->
      <div *ngIf="_class.isExpanded" class="d-lg-none mb-3" [@slideInOut]>

        <div class="cwk-pointer d-flex justify-content-center my-2" (click)="_class.isDetailsOpened = !_class.isDetailsOpened">
          Camp description
          <fa-icon class="ms-2" [icon]="_class.isDetailsOpened ? faMinusCircle : faPlusCircle"></fa-icon>
        </div>

        <div *ngIf="_class.isDetailsOpened" class="p-2" [@slideInOut]>
          <div *ngIf="_class.Tools" class="mb-1"><b>Languages / tools:</b>&nbsp;<span [innerHTML]="_class.Tools"></span></div>
          <div *ngIf="_class.Experience" class="mb-1"><b>Coder's Ladder Experience:</b>&nbsp;<span [innerHTML]="_class.Experience"></span></div>
          <div><b>Prerequisites:</b>&nbsp;<span *ngIf="!_class.Prerequisity">None</span></div>
          <span *ngIf="_class.Prerequisity" [innerHTML]="_class.Prerequisity"></span>
          <div class="mini-orange-bar mx-auto my-4"></div>
          <div *ngIf="_class.Description" class="text-justify mt-3" [innerHTML]="_class.Description"></div>
        </div>
      </div>

      <!-- CAMP SESSIONS (MOBILE) -->
      <div *ngIf="_class.isExpanded" class="sessions-container" [@slideInOut]>
        <h5 class="cwk-orange mb-1">Weeks offered:</h5>
        <div *ngFor="let week of _class.sessionsByWeekMobile">

          <!-- WEEK OF X -->
          <div class="mobile-week-header d-flex justify-content-between" [ngClass]="{'opened' : week.isOpen}" (click)="switchWeekOpen(week)">
            <div class="fw-bold">Week of {{ week.displayDate | date }}</div>
            <div>
              <div class="ampm" [hidden]="isOnline" [ngClass]="{ 'soft' : !week.offersAM }">AM</div>
              <div class="ampm" [hidden]="isOnline" [ngClass]="{ 'soft' : !week.offersPM }">PM</div>
              <fa-icon class="ms-2" [fixedWidth]="true" [icon]="week.isOpen ? faMinusCircle : faPlusCircle" size="lg"></fa-icon>
            </div>
          </div>

          <!-- IF OPENED, SHOW ALL OF THE SESSIONS FOR THE WEEK -->
          <div *ngIf="week.isOpen" [@slideInOut]>
            <div *ngFor="let session of week.sessions; last as isLast" [ngClass]="{'border-bottom' : !isLast}" class="px-2" (click)="goSignup(session)">

              <div class="d-flex flex-column justify-content-center item-session">

                <!-- LOCATION -->
                <div class="item mt-2 text-center" *ngIf="!isOnline && locations.length > 1">
                  <b>{{ session.Location }}</b>
                </div>

                <div class="cwk-pointer d-flex flex-row justify-content-between align-items-center">

                  <!-- PRICE AND SPOTS -->
                  <div class="text-center">
                    <div class="price">{{ session.Price4ClassSessions }} days</div>
                    <div class="price">
                      <div *ngIf="!session.Discounts.length">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</div>
                      <div *ngIf="session.Discounts.length">
                        <span class="non-discounted">{{ (session.Price | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}</span>
                        &#32;{{ (session.FinalPrice | currency: geolocation.currency : 'symbol' : '1.0-0') || '???' }}
                      </div>
                    </div>
                  </div>

                  <!-- DATE AND TIME -->
                  <div class="text-center" style="width: 150px;">
                    <!--div *ngIf="!isOnline" class="program-type {{ session.IsOnline ? 'hybrid' : 'classic' }} w-100">{{ session.IsOnline ? 'Hybrid+' : 'Classic' }}</div-->
                    <div>{{ session.StartUTC | date:'h:mm' }} - {{ session.EndUTC | date:'h:mm aa' }} {{ timezoneAbbr }}</div>
                    <div>{{ session.SessionStartUTC | date:'EEEE' }} - {{ session.SessionEndUTC | date:'EEEE' }}</div>
                    <div class="session-info" *ngIf="session.SessionInfo"><small>{{ session.SessionInfo }}</small></div>
                    <div *ngIf="session.NumberOccupied" class="number-occupied-mobile">POPULAR TIME</div>
                  </div>

                  <!-- WAITLIST/REGISTER & SPOTS LEFT -->
                  <div class="d-flex align-items-center">
                    <div class="text-center" style="width: 85px;">
                      <div class="weeks d-flex">
                        {{ session.Occupied ? 'WAITLIST' : 'REGISTER' }}
                        <fa-icon *ngIf="session.IsExternalRegistration" [icon]="faExternalLinkAlt" class="ms-2" ngbPopover="Camp registration handled externally" container="body" triggers="mouseenter:mouseleave"></fa-icon>
                      </div>
                      <ng-container *ngTemplateOutlet="spotsLeftTemplate; context: { session: session }"></ng-container>
                    </div>

                    <!-- ARROW -->
                    <fa-icon class="ms-1" [icon]="faChevronRight" size="lg"></fa-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- NO SESSIONS FOR ANY WEEKDAY -->
        <div *ngIf="!_class.sessionsByWeekMobile.length" class="more-coming-soon-mobile cwk-white cwk-darkyellow-bg fw-bold">
          <div class="item">No camps available right now</div>
          <div class="item">MORE COMING SOON!</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NO CLASSES TO SHOW -->
<div *ngIf="!classes2Show.length && !isReading && status" class="mt-3 text-center alert alert-warning" role="alert">
  <h3 class="mb-0 p-3">We're sorry. There are no camps available this week for your selected pathway.</h3>
</div>

<!-- SPOTS LEFT TEMPLATE -->
<ng-template #spotsLeftTemplate let-session="session">
  <div *ngIf="session.SpotsLeft <= 0" class="spots-left">FULL</div>
  <div *ngIf="session.NumberOccupied && session.SpotsLeft > 3" class="spots-left">POPULAR</div>
  <div *ngIf="session.SpotsLeft <= 3 && session.SpotsLeft > 0" class="spots-left">{{ session.SpotsLeft }} spot{{ session.SpotsLeft === 1 ? '' : 's' }} left!</div>
</ng-template>

<!-- ERROR -->
<cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
